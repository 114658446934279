<template>
  <!-- match div -->
  <div class=" feeds-matches">
    
    <div class="feed-schedule">
      <p class="league-schedule">
       {{ iso(match.country).name }}, {{ match.tournament }} <span class="schedule"  v-if="match.scores">- {{ match.scheduled | moment('HH:mm - D/MM')  }}</span>
      </p>
      <p class="text-right">
        <span class="league-schedule hidden-sm hidden-xs"> {{ match.scheduled | moment('HH:mm - D/MM')  }}</span> 
        <span class="text-orange visible-sm visible-xs m-scores" v-if="match.scores">{{ match.scores.home }} - {{ match.scores.away }}</span>
      </p>
    </div>
    
    <div class="d-grid main-two-column">
      <div class="teams">      
        <p>{{ match.home }}</p>
        <p>{{ match.away }}</p>
      </div>
      <div class="scores hidden-xs hidden-sm">
        <template  v-if="match.scores">
          <span>{{ match.scores.home }}</span>
          <span>{{ match.scores.away}}</span>
        </template>
      </div>
      <div class="d-grid three-column market-body">
        <template v-if="!started">
          <div v-frag v-for="mar in market.odds" :key="mar.id">
            <div
              :class ="{ 'selected' : mar.id == match.selected_odd_id }"
              @click="addToBetslip(mar.outcomeId,match.id,mar.name,market.id,mar.id,market)"
            >
              <span v-if="mar.outcomeId == 1">{{ mar.outcomeId }}</span>
              <span v-if="mar.outcomeId == 2">X</span>
              <span v-if="mar.outcomeId == 3">2</span>
              <span>{{ mar.odds }}</span>
            </div>
          </div> 
        </template>
        <template v-if="started">
          <div v-frag v-for="(_,nu) in [0,1,2]" :key="`ggf${nu}`">
            <no_odd />
          </div>
        </template>  
      </div>
    </div>
  </div>
  <!-- end -->
</template>
<script>

import { getCountryISO2 } from "@/utils/utility";
import BetslipService from "@/services/betslip";
import no_odd from './no-odds-button.vue';

export default {
  props:['match','started','bettype'],
  components: {
    no_odd
  },
  computed: {
    market() {
      return this.match.markets.find(x => x.key == 'match-result');
    }
  },
  methods: {
    iso: function (code) {
      const res = getCountryISO2(code,'sidebar')
      if(res) {
        return res
      } else {
        return 'DEFAULT'
      }
    },
    addToBetslip: function(outcome, match_id, name, mkt_id,odd_id,marketId) {   
      if(match_id == this.$props.match.id) 
      {
        this.$props.match.selected_odd_outcome = outcome;
        this.$props.match.selected_market = name;
        this.$props.match.selected_mkt_id = mkt_id;
        this.$props.match.selected_odd_id = odd_id;
        this.$props.match.selected_marketId = marketId.id;
        this.$props.match.selected_handicap = marketId.handicap;
      }     

      // add bet to slip
      BetslipService.addSingleJackpotBetslip(this.$props.match,this.$props.bettype);
      this.$store.dispatch("update_jackpot_state", {
        type: this.$props.bettype
      });
    }
  }
}
</script>
<style scoped>
  .d-grid {
    display: grid;
    gap: 4px !important;
    align-content: center;
    justify-content: center;
    grid-template-rows: 35px;
    
  }
  .d-grid.market-body {
    grid-template-rows: 35px;
  }
  .main-two-column {
    grid-template-columns: 1fr 1fr 60px;
  }
  
  .three-column {
    grid-template-columns: 1fr 1fr 1fr;

  }
  .feeds-matches {
    padding: 5px 0px;
    /* border: 1px solid #f7941e; */
    margin: 5px 0px;
  }
  .feeds-matches p {
    margin-bottom: 0px;
  }
  .feeds-matches .teams p {
    /* font-size: 13px; */
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .league-schedule {
    font-size: 11px;
    color: var(--a-grey);
  }
  .more-markets {
    color: var(--bg-orange);
    /* margin-bottom: 8px; */
    cursor: pointer;
  }
  .feed-schedule {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    /* margin-bottom: 1px; */
  }
  .feed-schedule p {
    margin-bottom: 0px !important;
    
  }
  div.selected {
    background-color: var(--bg-orange);
  }
  .scores {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    color: var(--bg-orange);
    font-size: 12px;
  }
  .m-scores {
    font-size: 11px;
  }
  @media(max-width:767px){
    .two-column {
      grid-template-columns: 40% 58%;
    }
    .main-two-column {
      grid-template-columns: 40% 58%;
    }
    span.schedule {
      display: inline;
    }
  }
  @media(min-width:768px){
    .two-column {
      grid-template-columns: 39% 60%;
    }
    .main-two-column {
      grid-template-columns: 1fr 1fr;
    }
    span.schedule {
      display: inline;
    }
  }
  @media(min-width:992px){
    .two-column {
      grid-template-columns: 1fr 1fr;
    }
    .main-two-column {
      grid-template-columns: 1fr 40px 1fr ;
    }
    span.schedule {
      display: none;
    }
  }
</style>