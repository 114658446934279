<template>
  <div class="container-fluid feeds ">
    <div class="col-md-2 hidden-xs hidden-sm"></div>
      <div class="col-md-8 col-sm-12 jackpots">
        <Loader v-if="load"/>
        <div class="" v-if="!load">
          <!-- <div class="top-nav">
            <span :class="{'active-jackpot': bettype =='mega_jackpot'}" @click="activateJackpot('mega_jackpot')">{{ $t('header.mega-jackpot') }}</span>
          </div> -->
          <the-carousel :slides="jackpotSlides"/>
          <br>
          <div :class="{ 'pdb' : games.length == games_length && !(games.some( obj => obj.started === 1 ))}">
            <div class="f-grid" v-if="!(games.some( obj => obj.started === 1 ))">
              <p></p>
              <div class="scores hidden-xs hidden-sm"></div>
              <h4 class="text-orange">{{ $t('leaderboard.ends') }}: {{ time | moment('DD-MM-YYYY HH:mm') }}</h4>
            </div>
            <match v-for="match in games" :key="match.id" :match="match" :started="started" :bettype="bettype"/>
          </div>
          <div class="jackpot-footer"  v-if="games.length == games_length && !(games.some( obj => obj.started === 1 )) ">
            <div class="jackpot-footer-body">
              <div class="main">
                <div class="auto-pick text-orange" @click="autoBet()">
                  <i class="icofont-gears"></i>
                  <span class="">{{ $t('betslip.quick-pick') }}</span>
                </div>
                <div class="hidden-xs"></div>
                <div class="place">
                  <router-link tag="button" to="/login" class="btn bg-orange" v-if="!loggedIn">{{ $t('header.login') }}</router-link>
                  <button class="btn bg-orange" :disabled="(bet_delay || betcount != games_length)" v-else @click="megaJackpotPlaceBetslip">{{ $t('betslip-select.place-jackpot') }}</button>
                  <div class="bin">
                    <i class="icofont-bin" @click="clearBet(bettype)"></i>
                  </div>
                </div>
              </div>
              <div class="">
                <p class="text-left">
                  <i class="icofont-exclamation-circle text-orange" v-if="(betcount < games_length)"></i> 
                  <i class="icofont-check-circled won" v-if="(betcount == games_length)"></i> 
                  Picked {{ betcount }}/{{ games_length }} Matches
                </p>
              </div>
            </div>
          </div>
        </div>        
      </div>
    </div>
</template>
<script>
import match from './../sections/feeds/jackpot-match.vue';
import {mapGetters} from "vuex";
import BetslipService from "@/services/betslip";
import Loader from "@/components/sections/loader.vue";

export default {
  components: {
    match,
    Loader
  },
  data() {
    return {
      type:'mega-jackpot',
      message: '',
      count_random:[],
      load: false,
      betcount: 0,
      bettype: 'mega_jackpot',
      bet_delay: false,
      use_bonus:false
    }
  },
  computed:{
    ...mapGetters({
        jackpot_games: 'jackpot_games',
        daily_jackpot_games: 'daily_jackpot_games',
        account: 'account',
        loggedIn: 'token',
        jackpotSlides:'jackpotSlides'
    }),
    started() {
     return this.games.some( obj => obj.started === 1)
    },
    games() {
      if (this.bettype == 'mega_jackpot') {
        return this.jackpot_games;
      } else {
        return this.daily_jackpot_games;
      }
    },
    games_length() {
      if (this.bettype == 'mega_jackpot') {
        return 17;
      } else {
        return 15;
      }
    },
    time() {
      let date = this.jackpot_games.find( (_,x) => x == 0);
      return date ? date.scheduled : 0;
    }
  },
  methods: {
    getRandomizer: function(bottom, top) {
      return Math.floor(Math.random() * (1 + top - bottom)) + bottom;
    },
    marketTranslate: function(mkt) {
      if(mkt.name == "Match Result") 
      {                
          return "1x2";
      }            
      else if( this.$i18n.te("markets." + mkt.marketId) ) 
      {                    
          let str = this.$i18n.t("markets." + mkt.marketId,"en");
          let fstr = this.$i18n.t("markets." + mkt.marketId,"fr");
                                              
          if(this.$i18n.locale != "en")
          {                    
              let mt = mkt.name.replace(str,fstr);
              return mt.toLowerCase()
          } 
          else
          {
              return mkt.name.toLowerCase()
          }    
                        
      } 
      else if(this.$i18n.te("markets." + mkt.key)) 
      {                
          return this.$i18n.t("markets." + mkt.key).toLowerCase();
      }
      else 
      {
          return mkt.name.toLowerCase();
      }
    },
    marketSpecifier: function (odd) {
      if( this.$i18n.te("markets." + odd.name.toLowerCase()) )
      {
          return  this.$i18n.t("markets." + odd.key).toLowerCase();
      }
      else if(this.$i18n.te("markets." + odd.key))
      {
          return  this.$i18n.t("markets." + odd.key).toLowerCase()
      }
      else
      {
          return odd.name
      }
    },
    autoBet: function() {
      this.count_random.length = 0;
      for (let index = 0; index < 17; index++) {
          let val = this.getRandomizer(1, 3);
          this.count_random.push(val);
      }
      for (let i = 0; i < this.games.length; i++) 
      {
          const markets = this.games[i].markets.find(g => g.marketId == 2 )                    
          this.games[i].selected_odd_outcome = markets.odds.find(g => g.outcomeId == this.count_random[i] ).outcomeId;
          this.games[i].selected_market = markets.odds.find(g => g.outcomeId == this.count_random[i] ).name;
          this.games[i].selected_mkt_id = markets.odds.find(g => g.outcomeId == this.count_random[i] ).marketId;
          this.games[i].selected_odd_id = markets.odds.find(g => g.outcomeId == this.count_random[i] ).id;
          this.games[i].selected_marketId = markets.id;
          this.games[i].selected_handicap = markets.handicap;
      }
      BetslipService.addJackpotBetslip(this.games,this.bettype)
    },
    getOnMounted: async function () {
      this.load = true;
      this.$Progress.start();
      await this.$store.dispatch("fetch_megajackpot_games");
      BetslipService.setJackpotSubject(this.bettype);
      this.load = false;
      this.$Progress.finish();
    },
    clearBet: function (params) {
      BetslipService.clearBetslip(params);
      this.$store.dispatch("update_jackpot_state", {
        type: this.bettype
      });
    },
    createBetslip: function (data, amount, bettype) {             
      if(data != null) {
        const betslip_obj = {
          amount: amount,
          use_bonus: this.use_bonus,
          bettype: bettype,
          multibet: data.length > 1 ? true : false,
          picks: []
        }
        data.forEach(el => {
          const tmpObj = {}
          tmpObj.name = el.home + ' vs ' +el.away;
          tmpObj.match_id = el.id;
          tmpObj.market_id = el.selected_marketId;
          tmpObj.scheduled = el.scheduled;
          tmpObj.outcome_id = el.selected_odd_outcome;
          tmpObj.specifier = (el.selected_handicap !='') ? el.selected_handicap : 'n/a';

          let mkt = el.markets.find( x => x.id === el.selected_marketId )

          el.markets.find( x => x.id === el.selected_marketId).odds.forEach(elem => {
            if (elem.outcomeId == el.selected_odd_outcome && elem.id == el.selected_odd_id) {
              tmpObj.pick = this.marketTranslate(mkt) + ' - ' + this.marketSpecifier(elem);
              tmpObj.odd_id = elem.id;
              tmpObj.odd = elem.odds;
              tmpObj.market = elem.marketId;
              tmpObj.outcome_id = elem.outcomeId;
            }
          });
          betslip_obj.picks.push(tmpObj);
        });
        return betslip_obj;
      }   
    },
    activateJackpot: async function(type) {
      this.bettype = type;
      this.bet_delay = false;

      if(this.bettype == 'mega_jackpot') {
        this.getOnMounted();
      } else {
        await this.$store.dispatch("fetch_minijackpot_games");
        BetslipService.setJackpotSubject(this.bettype);
      }
    },
    megaJackpotPlaceBetslip: function () {
      this.bet_delay = true
      let payload = this.createBetslip(this.games,2000,this.bettype);
      console.log(payload);

      BetslipService.placeMegaJackpot(payload).then( res => {
        if( res.success) 
        {
          this.$swal(this.$i18n.t('betslip-select.bet-success'),'','success');
          BetslipService.clearBetslip('megajackpot');
          this.bet_delay = false;
        }
        else 
        {
          if (!res.amount) {
            this.$swal(this.$i18n.t('betslip-select.balance-error'),'','error')
          }
          else {
            this.$swal(this.$i18n.t('betslip-select.bet-error'),'','error')
          }

          setTimeout(() => {
            this.bet_delay = false
          }, 3000)
        }
      }).catch( err => console.log(err))
    },
  },
  mounted() { 
    this.getOnMounted()
  },
  created() {
    this.jackpot_subscription = BetslipService.getJackpotBetslip(this.bettype).subscribe( data => {
      this.betcount = (data == null) ? 0 : data.length           
    })
  },
  beforeDestroy () {
    this.jackpot_subscription.unsubscribe();
  }
}
</script>
<style scoped>
.top-menu {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px;
  align-items: center;
}
.top-nav {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 5px 0px;
}

.jackpot-info {
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 15px;
}
.jackpot-info p,.jackpot-info h3 {
  margin: 0px;
}
.active-jackpot {
  color: var(--bg-orange);
}
.jackpot-footer {
  border-top: 1px solid var(--border-color);
  padding: 10px 0px;
}
.jackpot-footer .main, .f-grid{
  display: grid;
}
.f-grid {
  gap: 4px;
}
.f-grid h4 {
  margin: 5px 0px;
}
.jackpot-footer .main button {
  /* flex:1; */
  padding-left: 50px;
  padding-right: 50px;
}
.jackpot-footer .main div.auto-pick {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
}
.jackpot-footer .main div.auto-pick {
  padding: 2px 10px 2px 0px;
  cursor: pointer;
  text-align: left;
}
.jackpot-footer .main div.auto-pick i {
  font-size: 20px;
  margin-right: 2px;
}
.jackpot-footer .main div.place {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.jackpot-footer .main div.place button {
  flex:1;
}
.jackpot-footer .main div.bin {
  padding: 2px 0px 2px 10px;
  color: red;
  cursor: pointer;
  text-align: right;
}
.jackpot-footer .main div.bin i {
  font-size: 20px;
  margin-right: 0px;
}
.jackpot-footer .stake {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  width: 75%;
}

@media(max-width:767px){
    .f-grid {
      grid-template-columns: 40% 58%;
    }
    .pdb {
      padding-bottom: 50px;
      }
  }
  @media(min-width:768px){
    .f-grid {
      grid-template-columns: 1fr 1fr;
    }
    .pdb {
      padding-bottom: 150px;
      }
  }
  @media(min-width:992px){
    .f-grid {
      grid-template-columns: 1fr 40px 1fr ;
    }
    .pdb {
      padding-bottom: 50px;
    }
  }
</style>